<template>
  <!--
    The view for the On Delivery Calendar
  -->
  <Portlet
    :title="$t('deliveryCalendar')"
    icon="truck"
  >
    <template slot="buttons">
      <font-awesome-icon
        :class="['mr-3 alt-pointer color-primary', { 'fa-spin' : loading}]"
        icon="sync-alt"
      />
    </template>
    <div class="row">
      <div
        v-for="(dType, index) in deliveryTypes"
        :key="`li-item${ index }`"
        class="delivery-legend ml-3 row"
        @click="setDeliveryType(dType)"
      >
        <div
          :style="'background-color:' + dType.color + ';'"
          class="mt-1"
        />
        <p :class="['ml-2 mr-4', {'selected-type' : excludedTypes.includes(dType)}]">
          {{ dType.name }}
        </p>
      </div>
    </div>
    <DeliveryCalendar
      :excluded-types="excludedTypes"
      @setLoading="setLoading"
    />
  </Portlet>
</template>

<script>
export default {
  name: "DeliveryCalendarView",
  components: {
    DeliveryCalendar: () => import('@/components/OnCallDuty/DeliveryCalendar.vue'),
  },
  data () {
    return {
      excludedTypes: [],
      deliveryTypes: [
        {name: 'Delivery', color: '#ff0080'},
        {name: 'Desired Delivery', color: '#ff7dbe'},
        {name: 'Installation', color: '#0066cc'},
        {name: 'Desired Installation', color: '#459ff9'}
      ],
      loading: false
    }
  },
  metaInfo () {
    return {
      title: this.$t('deliveryCalendar')
    }
  },
  methods: {
    setLoading (val) {
      this.loading = val;
    },
    setDeliveryType (val) {
      if(this.excludedTypes.includes(val)) {
        this.excludedTypes = this.excludedTypes.filter(item => item !== val)
      }
      else {
        this.excludedTypes.push(val);
      }
    }
  }
}
</script>

<style scoped>
.delivery-legend p{
  font-size: 15px
}

.delivery-legend {
  cursor: pointer;
}

.delivery-legend p.selected-type {
  text-decoration: line-through;
  font-weight: bold;
}

.delivery-legend div {
  width: 15px;
  height: 15px;
  border-radius: 10px;
}
</style>